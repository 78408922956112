@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,600&display=swap");
* {
  margin: 0%;
  padding: 0%;
  font-family: Open Sans;
  text-decoration: none;
}

.navigation-wrapper {
  border: 1px solid black;
  display: flex;
  /* margin-left: -300px; */
}
.navigation-wrapper a {
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  text-decoration: none;
  cursor: pointer;
}
.unactive-link {
  color: #bebcbc;
  font-size: 17px;
  font-weight: 600;
}
.unactive-link:hover {
  color: #9e9d9d;
}
.active-link {
  font-weight: 600;
  color: black;
}
.top-bar--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0 0 5px #e2e2e2;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}
.top-bar-navigation--wrapper {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px; */
  width: 100%;
}
.full-top-navigation-bar-wrapper {
  width: 80%;
}
.top-bar-mobile-navigation--wrapper {
  display: none;
}
.top-bar-navigation--titles--container {
  display: flex;
  /* border: 1px solid black; */
  width: 50%;
  justify-content: space-evenly;
  align-items: center;
}
.top-bar-profile-notification-btn-wrapper {
  display: flex;
  /* border: 1px solid black; */
  width: 100px;
  justify-content: space-evenly;
  align-items: center;
}
.top-bar-logo {
  border-radius: 50% 50% 0 0;
  overflow: hidden;
}
.top-bar-logo img {
  width: 50px;
}

.top-bar-btns {
  padding: 6px 15px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  background-color: #274178;
}

.main {
  padding: 0px 50px;
  /* padding: 30px 50px; */
}

.greating-section-wrrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100px;
  margin: 20px 0;
}
.greatings-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 41px;
}
.greatings-desc {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(39, 65, 120, 1);
}
.navigation-profile-pic-wrapper {
  /* border: 1px solid black; */
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
}
.greating-section--profile--wrapper {
  /* border: 1px solid black; */
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greating-section--profile-content-wrapper {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 10px;
  position: relative;
}
.verified-tutor-status-icon {
  z-index: 20;
  position: absolute;
  left: -18px;
  bottom: 5px;
}

.verify-now-section--wrapper {
  display: flex;
  margin: 20px 0;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #bebdbd;
}
.verify-now-section--left--wrapper {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
}
.verify-now-section--left--warning-icon-wrapper {
  /* border: 1px solid black; */
  background-image: url("../public/warning-icon.svg");
  width: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
}
.verify-now-section--right--wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
  text-decoration: underline;
  font-weight: 600;
}

.subnavigation-section-wrapper {
  /* border: 1px solid black; */
}
.subnavigation-section--titles--wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  padding: 15px 10px;
}
.subnavigation-section-content--wrapper {
  /* border: 1px solid black; */
  min-height: 200px;
  display: flex;
}
.subnavigation-section-left-content--wrapper {
  max-height: 63vh;
  overflow-y: scroll;
}
.subnavigation-section-left-content--wrapper-unverified {
  width: 50%;
}
.subnavigation-section-left-content--wrapper-verified {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.student-card-wrapper-verified {
  width: 43%;
  margin: 10px;
}
.student-card-wrapper-unverified {
  width: 91%;
  margin: 10px auto;
}

.space-between-flex {
  display: flex;
  justify-content: space-between;
}
.student-card-wrapper {
  border: 0.5px solid rgba(88, 86, 86, 0.64);
  border-left: 15px solid rgba(39, 65, 120, 1);
  border-radius: 0 5px 5px 0;
  /* display: flex; */
  padding: 10px 15px;
  /* justify-content: space-between; */
  box-shadow: 0 0 1px rgba(88, 86, 86, 0.64);
}
.student-card-wrapper:hover {
  box-shadow: 0 0 5px rgba(88, 86, 86, 0.64);
}
.student-card-left-content-wrapper > div {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  /* padding: 3px 0; */
}
.student-card-left-content-wrapper img {
  margin-right: 5px;
  height: 18px;
}
.student-card-right-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.student-card-right-content-1 {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.myclasses-call-to-action-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 10px 15px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myclasses-call-to-action-icon img {
  width: 20px;
}
.student-card-name {
  color: #828282;
  font-size: 18px;
  font-weight: 600;
  line-height: 35px;
}
.student-card-subjects {
  color: #828282;
  font-weight: 600;
  line-height: 35px;
  font-size: 16px;
}
.student-card-subjects span {
  font-size: 14px;
  font-weight: 500;
}
.student-card-TC {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #828282;
}
.student-card-status-blink {
  /* border: 1px solid black; */
  width: 10px;
  height: 10px;
  box-shadow: 0 0 9px #ff9900;
  background-color: #ff9900;
  border-radius: 50%;
}
.student-card-status-title {
  color: #ff9900;
  font-weight: 600;
  text-transform: capitalize;
}
.yellow-applied-btn {
  background-color: #eab909;
  color: black;
  font-weight: 600;
}
.student-card-amount {
  color: #274178;
  font-weight: 600;
}
.student-card-right-content-1 > div {
  text-align: end;
}
.student-card-right-content-2 {
  text-align: end;
  display: flex;
  justify-content: end;
}
.student-card-right-content-2--status {
  color: #828282;
}
.student-card-right-content-2--btn {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subnavigation-section-right-content--wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.subnavigation-section-right-bigPic {
  /* border: 1px solid black; */
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subnavigation-section-right-smallPic {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-top: 20px;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  background-color: white;
}

body::-webkit-scrollbar-thumb {
  background-color: #c4c8cc;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #979899;
}
.subnavigation-section-left-content--wrapper::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.subnavigation-section-left-content--wrapper::-webkit-scrollbar-track {
  background-color: white;
}

.subnavigation-section-left-content--wrapper::-webkit-scrollbar-thumb {
  background-color: #c4c8cc;
  border-radius: 5px;
}

.subnavigation-section-left-content--wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #979899;
}
.subnavigation-section--titles--wrapper div {
  font-size: 14px;
  z-index: 10;
  padding-bottom: 3px;
  position: relative;
  cursor: pointer;
}
.sub-nav-notification {
  position: absolute;
  top: -90%;
  right: -15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  color: white;
  background-color: #eab909;
}
.active-sub-link {
  border-bottom: 4px solid #eab909;
  font-weight: 600;
}
.unactive-sub-link {
  font-weight: 600;
  border-bottom: 4px solid transparent;
}

@media (max-width: 768px) {
  .top-bar--wrapper {
    position: relative;
  }
  .full-top-navigation-bar-wrapper {
    /* border: 1px solid black; */
    width: 80%;
  }
  .top-bar-navigation--wrapper {
    display: none;
  }
  .top-bar-mobile-navigation {
    /* border: 1px solid rgba(0, 0, 0, 0.544); */
    z-index: 100;
    box-shadow: 0 0 2px #274178;
    position: fixed;
    width: 70vw;
    height: 50vh;
    background-color: rgba(255, 255, 255);
    right: 0;
    top: 0;
    transform: translateX(100%);
    overflow-x: hidden;
    transition: transform 0.3s ease-out;
    border-radius: 0 0 0 5px;
  }
  .top-bar-navigation--titles--container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
    height: 220px;
    padding: 20px;
    font-size: 15px;
    /* border: 1px solid black; */
  }
  .top-bar-navigation--titles--container .active-link {
    color: rgba(39, 65, 120, 1);
  }
  .top-bar-mobile-navigation-close-btn {
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    color: rgba(130, 130, 130, 1);
    cursor: pointer;
  }
  .open-mobile-navigation {
    transform: translateX(0%);
  }
  .top-bar-mobile-navigation--wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .top-bar-profile-notification-btn-wrapper {
    width: 60px;
    margin-top: 5px;
    justify-content: space-between;
  }
  .top-bar-navigation--logout-btn--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-top: 15%;
    color: red;
  }
  .top-bar--wrapper {
    padding: 0 20px;
  }
  .top-bar-logo img {
    width: 40px;
  }
  .main {
    padding: 0px 20px;
  }
  .greating-section-wrrapper {
    justify-content: center;
  }
  .greating-section-wrrapper {
    display: flex;
    height: 100px;
    margin: 0;
  }
  .greatings-title {
    font-size: 16px;
    line-height: 20px;
  }
  .greatings-desc {
    font-size: 14px;
    line-height: 15px;
  }
  .verified-tutor-status-icon {
    z-index: 20;
    position: absolute;
    left: -15px;
    bottom: 0px;
  }
  .verify-now-section--wrapper {
    margin: 0;
    margin-left: -5px;
    padding: 0px 5px 0 0;
    border-radius: 5px;
    width: 102%;
  }
  .verify-now-section--left--wrapper {
    width: 100%;
    font-size: 10px;
    line-height: 25px;
  }
  .verify-now-section--right--wrapper {
    width: 90px;
    font-weight: 600;
    font-size: 11px;
  }
  .verify-now-section--left--warning-icon-wrapper {
    width: 20px;
    margin: 3px;
  }
  .subnavigation-section--titles--wrapper {
    width: 100%;
    justify-content: space-evenly;
    padding: 8px;
    box-sizing: border-box;
  }
  .subnavigation-section--titles--wrapper div {
    font-size: 14px;
    padding-bottom: 3px;
    position: relative;
  }
  .sub-nav-notification {
    width: 12px;
    height: 12px;
    font-size: 8px;
    top: -20%;
    right: -10px;
  }

  .active-sub-link {
    border-bottom: 2px solid #eab909;
  }
  .unactive-sub-link {
    border-bottom: 2px solid transparent;
  }
  .subnavigation-section-left-content--wrapper {
    width: 100%;
  }
  .subnavigation-section-right-content--wrapper {
    display: none;
  }

  body::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .subnavigation-section-left-content--wrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .student-card-wrapper {
    border-left: 10px solid rgba(39, 65, 120, 1);
    margin: 10px auto;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  .student-card-left-content-wrapper img {
    height: 11px;
  }
  .student-card-name,
  .student-card-subjects {
    font-size: 12px;
    line-height: 20px;
  }
  .student-card-subjects span,
  .student-card-TC {
    font-size: 11px;
  }
  .subjects-list-inside-card {
    /* border: 1px solid; */
    width: 160px;
    height: auto;
    word-wrap: break-word;
    display: inline-block;
  }
  .student-card-right-content-wrapper {
    font-size: 11px;
    padding: 5px;
  }
  .forms-next-btn {
    padding: 5px;
    width: 80px;
  }
  .verify-your-account-title {
    font-size: 20px;
    line-height: 20px;
  }
}
