.personal-info--container{
    padding: 20px;
    /* padding: 40px 20px; */
    display: flex;
}
.personal-info--navigation--wrapper{
    width: 200px;
}
.personal-info--navigation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.personal-info--navigation>div{
    border-left: 4px solid transparent;
    margin: 5px 0;
    padding-left: 8px;
    cursor: pointer;
    font-size: 800;
}

.pathway-requestBtn--wrapper{
    margin: 20px 0;;
    display: flex;
    justify-content: space-between;
}
.pathway--wrapper{
    display: flex;
    align-items: center;

}
.pathway--wrapper > div{
    padding: 3px 10px;
}
.pathway--btn{
    border: 1px solid #D9D9D9;
    font-weight: 400;
    color: black;
    border-radius: 4px;
}
.pathway--wrapper .pathway--btn:last-child{
    border: 1px solid transparent;
    font-weight: 400;
    color: black;
    border-radius: 4px;

}

.personal-info--navigation .active-personal-info--navigation{
    border-left: 4px solid rgba(39, 65, 120, 1);
    padding-left: 8px;
    color: rgba(39, 65, 120, 1);
}
.personal-info--navigation .unactive-personal-info--navigation{
    border-left: 4px solid transparent;
    padding-left: 8px;
    color: #94A2AB;
}

.personal-info--content--page{
    width: 100%;
}

.personal-info--personal-content--wrapper{
    margin-top: 20px;
}
.personal-info--personal-content-1{
    display: flex;
}

.personal-info--personal-content-1-left{
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.personal-info--personal-content-profilePic-wrapper{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
}
.personal-info--personal-content-profilePic-wrapper .showRequest--Edit-btn{
    width: 100%;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    padding: 5px 0;
}
.personal-info--personal-content-profile-name{
    
    height: 100%;
    width: 47%;
    font-weight: 500;
    color: rgba(39, 65, 120, 1);
}
.personal-info--personal-content-1-right{
    width: 100%;
    color: #94A2AB;
    text-align: end;

}




.personal-info--personal-content-2{
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
}
.personal-info--personal-content-1-right img{
    cursor: pointer;
}
.personal-info--personal-content-subtitle{
    display: flex;
    align-items: center;
    margin-top: 18px;
}
.personal-info--personal-content-subtitle img{
    margin: 2px 5px;
}

.personal-info--personal-content-data{
    color: hsl(221, 51%, 31%);
    padding: 5px 10px;
}
.personal-info--personal-content--field-wrapper{
    font-weight: 600;
    width: 45%;
}
.address-component{
    
}
.address-component--wrapper{
    padding: 10px 25px;
    box-shadow: 0 0 3px #828282;
    margin: 10px 0;
    border-radius: 8px;
}
.address-component--wrapper > div{
    padding: 3px;
    font-weight: 500;
}

.showRequest-Save-wrapper{
    width: 80px;
    margin:20px auto 8px auto;

}

.showRequest-Save-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}
.none-display{
    display: none;
}

@media (max-width: 768px) {
    .pathway-requestBtn--wrapper{
        display: none;
    }
    .address-component{
        margin-top: 65px;
    }
    .personal-info--personal-content--wrapper{
        padding: 0 10px;
        margin-top: 65px;
    }
    .personal-info--personal-content-1-left{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .personal-info--personal-content-1-right{
        width: auto;
    }
    .personal-info--personal-content-profilePic-wrapper{
        width: 30%;
        margin-left: 5px;
    }
    .personal-info--personal-content-profile-name{
        width: 70%;
    }
    .edit-txt{
        display: none;
    }
    .personal-info--personal-content-2{
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .personal-info--personal-content--field-wrapper{
        width: 95%;
    }
    .personal-info--personal-content-data{
        padding: 0;
    }
    .personal-info--personal-content-data input{
        color: #828282;
        font-weight: 600;
    }
    .address-component--wrapper{
        padding: 10px;
        margin: 10px;
    }
    .address-component--wrapper >div,.address-component--wrapper >div:last-child>div{
        margin-top: 3px;

    }
    .address-component--wrapper >div:last-child{
        margin: 0;
    }
    .address-component--wrapper{
        border: 0;
    }

}