.applied-tutor-main {
  /* border: 1px solid black; */
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
}
.applied-dealings-details-wrapper {
  /* border: 1px solid black; */
  width: 35%;
}
.applied-dealings-tutors-datagrid-wrapper {
  /* border: 1px solid black; */
  height: 90vh;
  width: 62%;
}

.custom-cell-highlight {
  background-color: yellow;
  font-weight: bold;
  padding: 3px;
}

.dealings-details-label{
  padding: 10px;
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: rgba(39, 65, 120, 1);
}

.dealings-label-value{
  text-transform: capitalize;
  color: rgba(234, 185, 9, 1);
}

.dealings-lables-title{
  font-size: 18px;
  color:black;
  font-weight: 600;
  text-align: center;
  margin: 5px auto;
}

@media (max-width: 768px) {

  .applied-dealings-details-wrapper,
  .applied-dealings-tutors-datagrid-wrapper {
    width: 99%;
  }
  
  .applied-tutor-main {
    flex-direction: column;
  }

}
