.support-cards-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.support-card-wrapper{
    /* border: 1px solid rgb(70, 69, 69); */
    box-shadow: 0 0 2px black;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 20px;
    border-radius: 6px;
    min-height: 160px;
}
.support-card-img-wrapper{
    border: 4px solid rgba(39, 65, 120, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.support-card-img-wrapper img
{
    width: 45px;
}
.support-card-wrapper-desc{
    font-size: 14px;
    font-weight: 400;
}
.support-card-wrapper-title{
    font-size: 22px;
    font-weight: 600;
}

.small-section{
    /* border: 1px solid black; */
    margin: 50px 0 10px 0;
  
  }
  .small-blue-border{
    width: 11%;
    height: 7px;
    border-radius: 10px;
    background-color:rgba(234, 185, 9, 1);
    margin-bottom: 10px;
  }
  .support-contacts a{
    text-decoration: underline;
    color: rgba(234, 185, 9, 1);
    font-weight: 600;
    margin-left: 5px;
  }
  .small-section--text{
    display: flex;
  }
  .small-section--text-left--title{
    font-family: Open Sans;
    font-size: 25px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
  
  }
  
  .small-section--text-left{
    width: 50%;
  }
  .small-section--text-right{
    width: 50%;
  }

.faq-row {
    border: 2px solid rgba(39, 65, 120, 1) !important;
    margin: 15px 0;
    border-radius: 5px;
    padding: 0px 15px !important;
}


@media (max-width:756px) {

  .support-card-wrapper{
    box-shadow: 0 0 3px rgb(99, 98, 98);
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    min-height: 160px;
  }
  .support-card-img-wrapper{
    border: 4px solid rgba(39, 65, 120, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .support-card-img-wrapper img
  {
      width: 45px;
  }
  .support-card-wrapper-desc{
      font-size: 12px;
      text-align: center;
      font-weight: 400;
  }
  .support-card-wrapper-title{
      font-size: 16px;
      font-weight: 600;
  }
  .small-section--text{
    flex-direction: column;
  }
  .small-section--text-left{
    width: 100%;
  }
  .small-section--text-left > div:last-child{
    /* font-size: 13px; */

  }
  .small-section--text-right{
    font-size: 13px;
    width: 100%;
  }
  .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
    padding-right: 0em !important;
  }
  
}