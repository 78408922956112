.verification--wrapper{
    border: 2px solid rgba(107, 106, 106, 0.5);
    border-radius: 6px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.verify-your-account-title{
    font-size: 30px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    margin: 20px auto;
}
.verify-your-account-desc{
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
}
.verify-your-account-btn-wrapper{
    /* border: 1px solid black; */
    padding: 10px;
    width: 180px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: large;

}
.verify-your-account-content--wrapper{
    /* border: 1px solid black; */
    min-height: 55%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* min-width: 50%; */
    width: 900px;
}
.verification-steps-wrapper-container{
    width: 50%;
    min-width: 920px;
    max-width: 930px;
}
.verify-your-account-steps--wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;

}
.verify-your-account-step-container{
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid black; */
    width: 33.3%;

}
.verification-steps-wrapper-container .verify-your-account-step-container{
    height: 200px;
}
.verify-your-account-step-icon--wrapper{
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
}
.verify-your-account-step-count{
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
}
.verify-your-account-step-status--wrapper img{
    width: 15px;
}
.verify-your-account-step-status--wrapper{
    cursor: pointer;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    align-items: center;
    box-shadow: 0 0 3px rgba(39, 65, 120, 1);
}
.verify-your-account-step-status-blur-border{
    border: 4px solid transparent;
}
.verify-your-account-step-status-color-border{
    border: 4px solid rgba(39, 65, 120, 1);
    background-color: rgba(39, 65, 120, 1);
}
.verify-your-account-steps--overline{
    height: 4px;
    background-color: rgba(39, 65, 120, 1);
    border-radius: 5px;
    width: 90%;
    margin: 0 auto;
    margin-top: -76px;
}
.verify-your-account-step--step-desc{
    text-align: center;
    font-size: 16px;
    /* font-weight: 500; */
    line-height: 25px;
    letter-spacing: 0em;
    width: 99%;
    height: 50px;
    font-weight: 600;
    color: rgba(39, 65, 120, 1);
    /* border: 1px solid black; */
}


.step1-form-wrapper{
    /* border: 1px solid black; */
    text-align: center;
}
.step1-form-input-wrapper{
    border: 1px solid rgba(0, 0, 0, 0.518);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    padding:5px 10px;
    margin: 15px auto;
}
.step1-form-input-upload{
    color: #274178;
    cursor: pointer;
    text-decoration: underline;
}
.step1-form-desc{
    margin: 80px 0 20px 0;
    font-weight: 600;
}
.yellow-color{
    color: rgba(242, 192, 12, 1);

}
.step1-form-input-update{
    cursor: pointer;
    color: rgb(0, 153, 13);
    font-weight: 600;
    text-decoration: underline;
    display: inline;
    margin-right: 10px;
}
.forms-buttons--wrapper{
    /* border: 1px solid black; */
    width: 270px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
}
.forms-previous-btn{
    color: #fff;
    background-color: #828282;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    border: 0;
    cursor: pointer;
}
.forms-next-btn{
    cursor: pointer;
    color: #fff;
    border: 0;
    background-color: #274178;
    padding: 5px;
    border-radius: 5px;
    width: 100px;
}









.step2-form-wrapper,.step3-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 85px 0 50px 0;
    /* border: 1px solid black; */
}
.step2-form-payment-points-title{
    /* border: 1px solid black; */
    width: 90%;
    padding: 10px;
    color: white;
    font-weight: 600;
    background-color: #274178;
    border-radius: 5px;
    margin-top: 20px;
}
.step2-form-payment-points-title .amount{
    font-size: 24px;
    margin-right: 10px;
}
.step2-form-payment-img-wrapper{
    /* border: 1px solid black; */
    margin: 5px;
}
.step2-form-paynow-btn{
    margin: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    background-color: #15A924;
    cursor: pointer;
}
.step2-form-paymentCompleted-desc{
    color: #15A924;
    font-weight: 600;
}
.step2-form-payment-title-amount{
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    display: flex;
    text-align: center;
}
.step2-form-payment-desc{
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    /* color:rgba(39, 65, 120, 1); */
    text-align: center;
}
















.verify-account .reviews{
    padding: 40px 0;
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-around;
}
.verify-account .reviews div :first-child img{
    height: 40px;
}
.review-content{
    font-weight: 400;
    font-size: 12px;
}
.verify-account .reviews .star-icon{
    height: 15px;
}


@media (max-width: 768px) {
    .verify-account .reviews{
        padding: 20px 0;
        width: 90%;
    }
    .verify-your-account-steps--wrapper{
        justify-content: space-between;
    }
    .verify-your-account-content--wrapper{
        width: 99%;
    }
    
    .verification-steps-wrapper-container .verify-your-account-step-container{
        width: 33.3%;
        height: 190px;
    }
    .verify-your-account-step-icon--wrapper{
        height: 30px;
        width: 30px;
    }
    .verify-your-account-step-count{
        font-size: 18px;
        line-height: 23px;
    }
    .verify-your-account-step--step-desc{
        font-size: 11px;
        line-height: 14px;
        font-weight: 600;
        color: rgba(39, 65, 120, 1);
        height: 59px;
        width: 95%;
    }
    .verify-your-account-desc{
        font-size: 13px;
        font-weight: 500;
        line-height: 23px;
    }
    .verify-account .reviews{
        padding: 0;
        width: 95%;
    }
    .verify-account .reviews div :first-child img{
        height: 25px;
    }
    .review-content{
        font-weight: 400;
        font-size: 8px;
    }
    .verify-account .reviews .star-icon{
        height: 12px;
    }
    .reviewer-source-title{
        font-size: 12px;
    }
    .verify-your-account-title{
        font-size: 25px;
        /* line-height:0px; */
    }
    .verification--wrapper{
        min-height: 83vh;
        /* margin-top: 20px; */
    }
    .verification-steps-wrapper-container{
        width: 99%;
        min-width: 300px;
        max-width: 350px;
    }
    .verify-your-account-steps--overline{
        margin-top: -91px;
    }
    .step1-form-wrapper{
        border: 1px solid transparent;

        margin: 30px auto;
        /* margin-top: 40px; */
        padding: 0 10px;
    }
    .step2-form-payment-img-wrapper img{
        margin-top: 25px;
        height: 35px;
    }
    .step2-form-paynow-btn{
        margin: 5px;
        padding: 5px 8px;
        min-width: 90px;
    }
    .step2-form-payment-title-amount{
        font-size: 14px;
        line-height: 28px;
    }
    .step2-form-payment-desc{
        font-size: 14px;
        line-height: 20px;
    }
    .step3-form-wrapper{
        margin-top: 90px;
        padding: 0 10px;
    }
}