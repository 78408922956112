.student-page .student-card-wrapper-unverified{
    width: 43%;
    margin: 10px;
}

.student-page .subnavigation-section-left-content--wrapper{
    max-height: 80vh;
}

.students--container--nav-wrapper{
    /* border: 1px solid black; */
    width: 100%;
    margin: 20px auto;
}
.students--container--nav-wrapper .title{
    padding: 5px 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    margin: 0 auto;
    color: white;
    background-color: rgba(39, 65, 120, 1);
    width:max-content
    
}
.emptyTutors-container{
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(39, 65, 120, 1);
    font-weight: 600;
    font-size: 22px;
    text-align: center;
}


@media (max-width:756px) {
    .student-page .student-card-wrapper-unverified{
        width: 100%;
        margin: 10px;
    }
    
}