
.my-courses-component-wrapper{
    /* border: 1px solid black; */
}


.my-courses-component--main-title{
    color: rgba(39, 65, 120, 1);
    font-weight: 600;
    font-size: 20px;
    margin: 10px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-courses-component--sub-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px auto;
    color: rgba(39, 65, 120, 1);
    font-size: 16px;
    font-weight: 600;
}
.personal-info--content--page .my-courses-component--main-title,
.personal-info--content--page .my-courses-component--sub-title{
    display: none;
}
.forms-with-btn-container{

    box-shadow:0 0 3px rgba(0, 0, 0,.3);
    border-radius: 5px;
    padding: 20px 0;
    width: 95%;
    margin: auto;
}
.personal-info--content--page .forms-with-btn-container{
    width: 100%;
    padding: 0;
    box-shadow: 0 0 0;
}
.all-forms-wrapper{
    display: flex;
    justify-content: space-evenly;
    max-height: 75vh;
    overflow-y: scroll;
}
.all-forms-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 5px;
}

.all-forms-wrapper::-webkit-scrollbar-track {
    background-color: white;
}

.all-forms-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(39, 65, 120, 1);
    border-radius: 5px;
}

.all-forms-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: black;
}
.right-forms-wrapper,.left-forms-wrapper{
    width: 45%;
}
.personal-info--content--page .right-forms-wrapper,
.personal-info--content--page .left-forms-wrapper{
    width: 48%;
    
}
.personal-info--content--page .form-option{
    font-size: 14px;
}
.form--wrapper{
    /* border: 1px solid black; */
    border-radius: 5px;
    /* height: auto; */
    width: 100%;
    margin: 20px auto;
    /* padding: 10px; */
    transition: all 0.3s ease-out;   
}
.active-form-heading{
    background-color: rgba(39, 65, 120, 1);
    color: white;
    font-weight: 600;
}
.inactive-form-heading{
    background-color: white;
    color: rgba(130, 130, 130, 1);
    box-shadow: 0 0 2px rgba(130, 130, 130, 1);
    font-weight: 600;
}
.inactive-form-heading:hover{
    background-color: white;
    color: rgb(114, 114, 114);
    box-shadow: 0 0 5px rgba(130, 130, 130, 1);
}
.form-heading{
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 30px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form-options--wrapper{
    /* border: 1px solid black; */
    padding: 10px 0 20px 0 ;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    /* align-items: center; */
}
.form-option{
    /* border: 1px solid black; */
    margin-left: 10px;
    cursor: pointer;
    min-width: 42%;
    max-width: 75%;
}
.my-courses-component-btns-wrapper{
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.primary-btn,.secondary-btn{
    /* border: 1px solid black; */
    width: 150px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.primary-btn{
    background-color: rgba(39, 65, 120, 1);
    color: white;
    font-weight: 600;
}
.secondary-btn{
    background-color: rgba(130, 130, 130, 1);
    color: white;
    font-weight: 600;

}
  
input[type='checkbox'],input[type='radio'] {
    accent-color: rgba(242, 192, 12, 1);
    transform: scale(1.5);
    margin: 10px;
}
  


@media (max-width: 768px) {

    .all-forms-wrapper::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    .right-forms-wrapper,.left-forms-wrapper{
        width: 90%;
        margin: auto;
    }
    .all-forms-wrapper{
        display: block;
    }
    .forms-with-btn-container{

        box-shadow:0 0 0px rgba(0, 0, 0,.3);
        padding: 0px 0;
        width: 100%;
    }
    .form-option{
        margin-left: 5px;
        min-width: 48%;
        max-width: 95%;
    }
}