.franchiseForm h1 {
  font-family: Open Sans;
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(39, 65, 120, 1);
}

.franchiseForm-article ul {
  padding: 0 0 0 12px;
}
.franchiseForm-article {
  font-family: Open Sans;
  font-size: 19px;
  font-weight: 400;
  text-align: left;
  padding: 20px 5px;
}

.franchiseForm-title {
  font-family: Open Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  margin-top: 30px;
  /* text-decoration: underline; */
}

.franchiseForm form {
  /* border: 1px solid black; */
  margin: 20px auto 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.franchiseForm form div {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
}

.small-form-input-component {
  padding: 5px;
  width: 48%;
}
.franchiseForm button {
  padding: 8px 40px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 5px;
  background-color: #365fb5;
  color: white;
  border: 0 solid transparent;
}
.small-form-input-component label,
.long-form-input-component label {
  font-size: 18px;
  text-transform: capitalize;
  padding: 3px;
  font-weight: 600;
}
.small-form-input-component input,
.long-form-input-component textarea {
  font-size: 17px;
  padding: 5px;
  border-radius: 5px;
  border: 3px solid rgba(242, 192, 12, 1);
  font-weight: 500;
}

.yellow-color {
  color: rgba(242, 192, 12, 1);
}
.counselling-slider {
  padding-top: 50px;
  margin: 0 auto;
  max-width: 45%;
}
.counselling-top-section {
  /* border: 1px solid black; */
  padding-top: 20px;
  display: flex;
  overflow: hidden;
}
.counselling-form-wrapper {
  /* border: 1px solid black; */
  width: 45%;
  margin: 0 auto;
}
.counselling-form form div {
  display: block;
  width: 100%;
  margin: 5px auto;
}
/* .franchiseForm form div {
  display: block;
  width: 100%;
  margin: 5px auto;
} */
.personal-info--content--page .main {
  padding: 0;
  margin-top: 0;
}

.small-form-input-component input,
.long-form-input-component textarea {
  min-height: 30px;
}

.long-form-input-component {
  padding: 5px;
  /* border: 1px solid black; */
  width: 100%;
}
@media (max-width: 768px) {
  .franchiseForm h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  .franchiseForm-title {
    text-align: center;
    font-size: 20px;
  }
  .small-form-input-component {
    padding: 5px;
    width: 100%;
  }
  .counselling-top-section {
    flex-direction: column;
  }
  .counselling-form-wrapper,
  .counselling-slider {
    width: 100%;
    padding-top: 0;
    max-width: 90%;
  }
}
